import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import { useSocket } from '../../contexts/socketContext';
import ChatComponent from '../../pages/_common/ChatComponent';
import newMessageAudio from '../../assets/new-message.wav'
import showNotification from '../../components/extras/showNotification'

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const socket = useSocket();

	const [isChatOpen, setIsChatOpen] = useState(false)
	const [chatHasNew, setChatHasNew] = useState(false)
	const [userRole, setUserRole] = useState("")
	const [newMessagesFrom, setNewMessagesFrom] = useState<string[]>([])

	const userData = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))

	useEffect(()=>{
		setUserRole(userData?.role?.type)
	},[])

	useEffect(()=>{
		if(!isChatOpen && socket){
			socket.on('receive_message', (data:any) => {
				console.log('Store Header receive_message', data)
				if(data.senderUsername){
					let prev = newMessagesFrom;
					prev.push(data.senderUsername)
					setNewMessagesFrom(prev)
				}
				console.log('newMessagesFrom',newMessagesFrom)
				setChatHasNew(true);
				const audio = new Audio(newMessageAudio);
				audio.play();
				showNotification(
					<span className="d-flex align-items-center">
						<span>Νέο μήνυμα από: {data.senderUsername}</span>
					</span>,
					`${data.message}`
				)
			})
		}
	},[isChatOpen])
	
	const toggleChat = () => {
		if(!isChatOpen) setChatHasNew(false);
		setIsChatOpen(!isChatOpen)
	}

	return (
		<>
			<Routes>
				{contents && contents.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				))}
				<Route path='*' element={<PAGE_404 />} />
			</Routes>
				{userRole ? (
					<>
						{/* Chat Icon */}
						<div className="transporters-chat-icon" onClick={toggleChat}>
							{chatHasNew && (<div className='chatNewMsg'></div>)}
						</div>

						{/* Chat Popup */}
						{isChatOpen && (
							<div className="transporters-chat-wrapper">
								<div className="transporters-chat-header">
									Live Chat
									<span style={{ float: 'right', cursor: 'pointer', fontSize: 'x-large', marginTop: '-8px' }} onClick={toggleChat}>
										{' '}
										&times;{' '}
									</span>
								</div>
								<div style={{ flex: 1, overflowY: 'auto' }}>
									<ChatComponent newMessagesFrom={newMessagesFrom} newMsgSetter={(d:any)=>{setNewMessagesFrom(d)}} />
								</div>
							</div>
						)}
					</>
				) : ''}
		</>
	);
};

export default ContentRoutes;
