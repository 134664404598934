import io from 'socket.io-client'
import { useState, useEffect,useRef } from 'react'
import apiService from '../../services/api'
import { API_BASE_URL } from '../../config/constants'
import { useSocket } from '../../contexts/socketContext'

// const socket = io(`${API_BASE_URL}`)

function ChatComponent({newMessagesFrom=[],newMsgSetter}) {
	const socket = useSocket()
	const [messages, setMessages] = useState([])
	const [message, setMessage] = useState('')
	const [users, setUsers] = useState([])
	const [selectedUser, setSelectedUser] = useState(null)
	const [selectedUserType, setSelectedUserType] = useState('distributor')
	const textAreaRef = useRef(null)
	const [view, setView] = useState('userList') // 'userList' or 'chatMessages'
	const user = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))
	const userId = `user-${user.id}`

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await apiService.get('transporters/getChatUsers')
				console.log('fetch response:',response.data)
				sortArrayByNewMessages(response.data,newMessagesFrom)
				setUsers(response.data)
			} catch (error) {
				console.error('Error fetching users:', error)
			}
		}
		console.log('socket in chat component',socket)
		fetchUsers()
		console.log('join_chat_room', `user:${userId}`)
		socket.emit('join_chat_room', `user:${userId}`)
		socket.on('receive_message', (data) => {
			console.log('receive_message', data)
			if(data.senderUsername) data.senderId = data.senderUsername
			setMessages((prevMessages) => [...prevMessages, data])
		})
		socket.on('ack_of_message', (data) => {
			console.log('ack_of_message', data)
		})

		return () => {
			socket.off('receive_message')
		}
	}, [])

	useEffect(() => {
		if (selectedUser) {
			console.log('selectedUser', selectedUser)
			newMsgSetter(newMessagesFrom.filter(u=>u!=selectedUser.username))
			const fetchMessages = async () => {
				try {
					const response = await apiService.get(`transporters/chatMessages/${selectedUser.id}`)
					console.log(response)
					setMessages(response.data)
				} catch (error) {
					console.error('Error fetching messages:', error)
				}
			}

			fetchMessages()
		}
	}, [selectedUser])

	const handleUserClick = (user) => {
		setMessages([])
		setSelectedUser(user)
		setView('chatMessages')
	}

	const handleBackClick = () => {
		setSelectedUser(null)
		setView('userList')
	}

	const sortArrayByNewMessages = (arr, newMessagesFrom) =>{
		return arr.sort((a, b) => {
		  const aHasMessage = newMessagesFrom.includes(a.username);
		  const bHasMessage = newMessagesFrom.includes(b.username);
	  
		  // Items with usernames in newMessagesFrom come first
		  if (aHasMessage && !bHasMessage) return -1;
		  if (!aHasMessage && bHasMessage) return 1;
	  
		  // Maintain original order if both are in or not in newMessagesFrom
		  return 0;
		});
	  }
	  
	const sendMessage = () => {
		if (!message || message.length < 1) return
		const messageData = {
			senderId: userId,
			receiverId: `user-${selectedUser.id}`,
			message: message,
			timestamp: new Date(),
		}
		console.log(messageData);

		socket.emit('send_message', messageData)
		messageData.senderId = user.username
		setMessages((prevMessages) => [...prevMessages, messageData])
		setMessage("");
		setTimeout(()=>{
			textAreaRef.current.selectionStart = 0;
			textAreaRef.current.selectionEnd = 0;
			textAreaRef.current.focus(); // Optional: focus the textarea
		},10)
	}

	return (
		<>
			{view === 'userList' && (
				<div>
					{
						user?.role?.type=="store" ? 
							<h5 style={{fontSize: '14px', padding: '3px', textAlign: 'center', background: '#eaeaea', margin:0}}>Διαθέσιμοι διαχειριστές</h5>
						:

							<h5 style={{fontSize: '14px', display: 'flex', background: '#eaeaea60', justifyContent:'space-between', alignitems: 'center'}}>
								<span onClick={()=>{setSelectedUserType('distributor')}} style={{ padding:'3px',backgroundColor:selectedUserType=="distributor"?'#c7c7c7':'transparent',cursor:'pointer', textAlign:'center', width:'100%'}}>Διανομείς
									{
										users.filter(u=>u.role.type=="distributor" && newMessagesFrom.includes(u.username)).length>0 
										? <div className='chatNewMsg userType'></div> : ''
									}
								</span>
								<span onClick={()=>{setSelectedUserType('store')}} 	  style={{ padding:'3px',backgroundColor:selectedUserType=="store"?'#c7c7c7':'transparent',cursor:'pointer', textAlign:'center', width:'100%'}}>Καταστήματα
									{
										users.filter(u=>u.role.type=="store" && newMessagesFrom.includes(u.username)).length>0 
										? <div className='chatNewMsg userType'></div> : ''
									}
								</span>
							</h5>
					}
					<ul style={{ listStyle: 'none', padding:'0 1em' }} className='livechat-users-list'>
						{
							user?.role?.type=="store" ? (
								// list for store chat here
								users.map((user) => (
									<li key={user.id} style={{ cursor: 'pointer', marginBottom: '10px', fontWeight: newMessagesFrom.includes(user.username) ? 'bold' : 'normal' }} onClick={() => handleUserClick(user)}>
										{user.firstname ? (user.firstname+' '+user.lastname) : user.username}
										{newMessagesFrom.includes(user.username) ? <div className='chatNewMsg user'></div> : ''}
									</li>
								))
							) : (
								//list for admin chat here
								users.map((user) => {

									if (user.role.type==selectedUserType) return (
										<li key={user.id} style={{ cursor: 'pointer', marginBottom: '10px', fontWeight: newMessagesFrom.includes(user.username) ? 'bold' : 'normal' }} onClick={() => handleUserClick(user)}>
											{user.firstname ? (user.firstname+' '+user.lastname) : user.username}
											{newMessagesFrom.includes(user.username) ? <div className='chatNewMsg user'></div> : ''}
										</li>
									)
								}
								)
							)
						}
					</ul>
				</div>
			)}
			{view === 'chatMessages' && selectedUser && (
				<>
					<h5 style={{fontSize: '14px', padding: '3px',textAlign: 'center', background: '#eaeaea',  margin: '0', cursor: 'pointer'}} onClick={handleBackClick}>← Πίσω στην λίστα χρηστών</h5>
					<div className="trannsporters-chat-inner">
						<div className="transporters-chat-messages">
							{messages.map((msg, index) =>
								msg.content ? (
									<div key={index}>
										{' '}
										<b>{msg.sender}:</b> {msg.content}{' '}
									</div>
								) : (
									<div key={index}>
										{' '}
										<b>{msg.senderId}:</b> {msg.message}{' '}
									</div>
								)
							)}
						</div>
						<div className="transporters-message-bar">
							<textarea
								ref={textAreaRef}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										sendMessage()
									}
								}}
								onChange={(e) => {
									setMessage(e.target.value)
								}}
								value={message}
							></textarea>
							<button onClick={sendMessage} className='chat-btn-send'>
							</button>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default ChatComponent
