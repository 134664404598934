import React, { FC, ReactNode, useContext, useRef } from 'react'
import classNames from 'classnames'
import { motion, MotionStyle } from 'framer-motion'
import ThemeContext from '../../contexts/themeContext'
import Tooltips from '../../components/bootstrap/Tooltips'
import useAsideTouch from '../../hooks/useAsideTouch'
import asides from '../../routes/asideRoutes'
import Swal from 'sweetalert2'

interface IAsideHeadProps {
	children: ReactNode
}
export const AsideHead: FC<IAsideHeadProps> = ({ children }) => {
	return <div className="aside-head">{children}</div>
}

interface IAsideBodyProps {
	children: ReactNode
}
export const AsideBody: FC<IAsideBodyProps> = ({ children }) => {
	return <div className="aside-body">{children}</div>
}

interface IAsideFootProps {
	children: ReactNode
}
export const AsideFoot: FC<IAsideFootProps> = ({ children }) => {
	return <div className="aside-foot">{children}</div>
}

interface IAsideProps {
	children: any
}
const Aside: FC<IAsideProps> = ({ children }) => {
	const { asideStatus, setAsideStatus, mobileDesign } = useContext(ThemeContext)

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch()

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true'

	const constraintsRef = useRef(null)

	const classes = classNames(
		'aside',
		{ open: mobileDesign ? !asideStatus : asideStatus },
		{
			'aside-touch-bar': hasTouchButton && isModernDesign,
			'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
			'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
		}
	)
	const asideCaretClass = 'asside-caret '+(asideStatus ? 'open':'');
	const logout = ()=>{
		Swal.fire({
			icon:'question',
			text:'Θέλετε σίγουρα να αποσυνδεθείτε;',
			showDenyButton:true,
			focusDeny:true,
			denyButtonText:'Άκυρο',
			confirmButtonText:"Ναι, αποσύνδεση"
		}).then(res=>{
			if(res.value){
				window.localStorage.removeItem('transporters_user');
				window.localStorage.removeItem('jwtToken');
				window.location.reload();
			}
		})
	}

	return (
		<>
			{mobileDesign ? (
				<aside className={classes}>{children}</aside>
			) : (
				<>
					<div className={asideCaretClass} onClick={()=>{setAsideStatus(!asideStatus)}}>
						<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon navigation-arrow" data-name="Material--ChevronRight"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path></svg>
					</div>
					<motion.aside style={asideStyle as MotionStyle} className={classes}>
						{children}
						{/* <a className="navigation-link navigation-link-pill aside-logout" onClick={logout}>
							<span className="navigation-link-info">
							<svg viewBox="0 0 24 24" fill="currentColor" style={{transform:'rotate(180deg)'}} className="svg-icon--material svg-icon navigation-icon" data-name="Material--Login"><path fill="none" d="M0 0h24v24H0z"></path><path d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"></path></svg>
								<span className="navigation-text">Αποσύνδεση</span>
							</span>
						</a> */}
					</motion.aside>
				</>
			)}

			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className="aside-drag-area" ref={constraintsRef} />
					<Tooltips title="Toggle Aside" flip={['top', 'right']}>
						<motion.div
							className="aside-touch"
							drag="x"
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	)
}

export default Aside
